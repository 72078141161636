<template>
	<div class="grid">
		<div class="col-12">
            <Loading v-if="loading"/>
			<div class="card" v-if="!loading">
                <h2>Produtos</h2>

                <table>
                    <thead>
                        <th>SKU</th>
                        <th>Nome</th>
                        <th>Marketplaces</th>
                    </thead>
                    <tbody>
                        <tr v-for="produto in this.produtos" :key="produto.id" style="height:55px;">
                            <td>{{produto.sku}}</td>
                            <td>{{produto.nome}}</td>
                            <td>
                                <div v-for="(marketplace) in this.integracoes" :key="marketplace.id" style="float:left; margin-right:20px">
                                    <img :src="marketplace.imagem" class="img_marketplace">
                                    <input type="checkbox" v-model="produto.integracoes"  :value="marketplace.id" >
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
			</div>

            <div class="div_botoes">
                <Button label="Fazer Importação" class="p-button-primary btn_success"  @click="fazerImportacao()" />
                <Button label="Cancelar" class="p-button-primary btn_cancel"  @click="modoVisualizarProduto()"/>
            </div>
		</div>
	</div>
</template>
<script>
import Api from '../service/api';


export default {
    
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            produtos:[],
            integracoes:[],
            api: "",
        }
    },
    created(){
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
    },
    mounted(){
        this.listarIntegracoes()
        this.listarProdutos()
    },
    methods:{
        listarProdutos() {
            this.loading = true;
            this.api = new Api();
            this.api.listarProdutosAtivos(this.token,this.empresa_id).then(data => {
                this.produtos = data.data.retorno
                this.loading = false
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        listarIntegracoes() {
            this.api = new Api();
            this.api.listarIntegracoes(this.token,this.empresa_id).then(data => {
                this.integracoes = data.data
                this.loading = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        fazerImportacao(){
            this.api = new Api();
            this.api.importarAnuncioPorProduto(this.token,this.empresa_id,this.produtos).then(data => {
                this.integracoes = data.data
                this.loading = false;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        }
    }
}
</script>
<style scoped>
table{
    width:100%;
}

thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
th{
    text-align:left;
}
.img_marketplace{
    float: left;
    border-radius: 50%;
    width:35px;
    height:35px;
    border: #ccc solid 1px;
    padding:5px;
    margin-right:5px;
    margin-top:-5px;
}

.div_botoes{
    margin-bottom:10px;
    width:100%;
    text-align:center;
}
.btn_cancel{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
    margin-left:10px;
    width: 15rem;
}
.btn_cancel:hover{
    background-color:#9d9d9d; 
    border:#9d9d9d solid 1px;
}
.btn_success{
    width: 15rem;
}
.conteudo_btn_voltar{
    color:#488EFF;
    font-size:14pt;
    float: left;
    margin-left:5px;
    margin-top:-2px;
}
.btn-voltar img{
    float: left;
    margin-top:3px;
}
</style>